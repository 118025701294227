import React from "react";
import { motion } from 'framer-motion'


// define your variants in const

const easeIn = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
        delayChildren: 0.5
        }
    }
}

const jumpHero = {
    hidden: { opacity: 0},
    show: {
        opacity: 1,
        transition: {
            delayChildren: 1
          }
    }
}

function IndexPage() {
    // const [menu, setMenu] = useState(false);
    return (
        <>
            <div className="bannerlee relative w-full h-full pb-6 pt-4">
                <div className="block">
                        <div className="absolute opacity-70 bg-cover bg-center w-full h-full inset-0 bg-gray-900" />
                    </div>
                <div className="relative md:px-20 container mx-auto md:flex items-center">
                    <motion.div className="text-color md:w-2/3 mx-auto py-28 text-center"
                    initial="hidden"
                    animate="show"
                    variants={easeIn}
                    >
                        <div className="f-f-r text-4xl lg:text-4xl text-white xl:pt-6">
                            <h2>News Updates</h2>
                        </div>
                        <div className="f-f-r text-lg lg:text-lg text-white xl:pt-6">
                            <h2>Coming Soon!</h2>
                        </div>
                    </motion.div>
                </div>
            </div>


            <style>{`
        .top-100 {
            animation: slideDown .5s ease-in-out;
        }

        .bannerlee{
            background-image: url('assets/images/banner.png');
            background-repeat: no-repeat;
            background-size: cover;
        }

        @keyframes slideDown {
            0% {
                top: -50%;
            }

            100% {
                top: 0;
            }
        }

        * {
            outline: none !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        } `}</style>
        </>
    );
}

export default IndexPage;
