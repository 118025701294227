import React from "react";
import { SiteMeta } from "../general/seo";
import { TopBar, BottomBar } from "../general/extremeBars";
import NavBar from '../general/navbar'
import Hero from './hero'



export default function News(){

return (<>
<SiteMeta title="News and Updates - Psaltry International Company Limited" description="Official Psaltry Company Information" />
<div className="dark:bg-gray-800">
<TopBar/>
<NavBar />
<Hero />


<div className="absolute w-full bottom-0">
<BottomBar />
</div>
</div>
</>);

}